import { IStore } from '~/redux/IStore';
import { createSelector } from 'reselect';
import { usersAdapter } from '~/redux/modules/usersModule';
import { selectDay } from './stateSelectors';
import { selectShifts } from './shiftsSelectors';
import moment from 'moment';
import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { TYPE_ROLE, FORMAT_MOMENT } from '~/helpers/constants';
import { selectWorkShiftIds } from './stateSelectors';
import { selectDepartmentIds } from './stateSelectors';
import containsArray from '~/helpers/containsArray';
import { selectUserRole } from '~/redux/selectors/authSelectors';
import { selectDepartmentId } from './stateSelectors';
import { getLocalMomentTime } from '~/helpers/convertToUnix';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';

const selectSelf = (state: IStore) => state;

export const usersSelectors = usersAdapter.getSelectors<IStore>(state => state.users);

export const selectUsers = createSelector(
  (state: IStore) => usersSelectors.selectAll(state),
  users => users,
);

export const selectUser = (userId: number) =>
  createSelector(
    (state: IStore) => usersSelectors.selectAll(state),
    users => {
      return users.find(user => user.id === userId);
    },
  );

export const selectFiredUsers = createSelector(
  (state: IStore) => usersSelectors.selectAll(state),
  users =>
    users.filter(
      user => [TYPE_ROLE.WORKER, TYPE_ROLE.SUPERVISOR].includes(user.role_id) && user?.is_fired,
    ),
);

export const selectWorkingUsers = createSelector(
  (state: IStore) => usersSelectors.selectAll(state),
  users =>
    users.filter(
      user => [TYPE_ROLE.WORKER, TYPE_ROLE.SUPERVISOR].includes(user.role_id) && !user?.is_fired,
    ),
);

export const selectWorkingUsersByIds = (ids: number[]) =>
  createSelector(
    (state: IStore) => usersSelectors.selectAll(state),
    users => users.filter(user => ids.includes(user.id) && !user?.is_fired),
  );

export const selectProfile = createSelector([selectUsers, selectSelf], (users, state) => {
  return users.find(user => user.id === state.auth.user.id);
});

export const selectTodayUsers = createSelector(
  [
    selectUsers,
    selectShifts,
    selectDay,
    selectWorkShiftIds,
    selectDepartmentIds,
    selectUserRole,
    selectDepartmentId,
    selectCompanyOptions,
  ],
  (users, shifts, day, workShiftIds, departmentIds, role, departmentId, options) => {
    const usersIds = shifts
      .filter(shift => {
        const shiftDate = getLocalMomentTime(shift.start_time, options.time_zone).format(
          FORMAT_MOMENT.DASH_YYYYMMDD,
        );

        return shiftDate === day && workShiftIds.includes(shift.working_shift_id);
      })
      .map(shift => shift.user_id);
    if ([TYPE_ROLE.SUPERVISOR].includes(role)) {
      return users.filter(
        user => usersIds.includes(user.id) && user.departments_ids.includes(departmentId),
      );
    }
    return users.filter(
      user => usersIds.includes(user.id) && containsArray(departmentIds, user.departments_ids),
    );
  },
);

export const selectUsersExcludingToday = createSelector(
  [selectUsers, selectShifts, selectDay, selectWorkShiftIds, selectCompanyOptions],
  (users, shifts, day, workShiftIds, options) => {
    const usersIds = shifts
      .filter(shift => {
        const shiftDate = getLocalMomentTime(shift.start_time, options.time_zone).format(
          FORMAT_MOMENT.DASH_YYYYMMDD,
        );

        return (
          moment(shiftDate).unix() * 1000 === Date.parse(day) &&
          workShiftIds.includes(shift.working_shift_id)
        );
      })
      .map(shift => shift.user_id);

    return users
      .filter(user => !usersIds.includes(user.id))
      .filter(
        user => [TYPE_ROLE.WORKER, TYPE_ROLE.SUPERVISOR].includes(user.role_id) && !user?.is_fired,
      );
  },
);

export const selectUsersInViewIds = createDraftSafeSelector(
  selectSelf,
  state => state.users.viewIds,
);

export const selectUsersTotalCount = createDraftSafeSelector(
  selectSelf,
  state => state.users.totalCount,
);

export const selectUsersInView = createSelector(
  [selectUsers, selectUsersInViewIds],
  (users, viewIds) => {
    return users
      .filter(user => viewIds.includes(user.id))
      .sort((userA, userB) => viewIds.indexOf(userA.id) - viewIds.indexOf(userB.id));
  },
);

export const selectCurrentWorker = (userId: number) =>
  createDraftSafeSelector(selectSelf, state => {
    if (!userId) {
      return null;
    }

    const currentUser = state.users.entities[userId];

    return {
      ...currentUser,
      professionName: currentUser?.profession_id
        ? state.professions.entities[currentUser.profession_id]?.name
        : '',
      roleName: currentUser?.role_id ? state.roles.entities[currentUser.role_id]?.name : '',
      departments: currentUser?.departments_ids
        ?.reduce((accumulator, currentValue) => {
          return accumulator + state.departments.entities[currentValue]?.name + ', ';
        }, '')
        .slice(0, -2),
      view_departments: currentUser?.view_departments_ids
        ?.reduce((accumulator, currentValue) => {
          return accumulator + state.departments.entities[currentValue]?.name + ', ';
        }, '')
        .slice(0, -2),
    };
  });

export const selectDataUsers = createSelector(
  (state: IStore) => usersSelectors.selectAll(state),
  users =>
    users
      .map(user => ({
        id: user.id,
        profession_id: user.profession_id,
        department_ids: user.departments_ids,
        role_id: user.role_id,
        is_fired: user.is_fired,
        name:
          user?.lastname +
          ' ' +
          user?.firstname?.charAt(0).toUpperCase() +
          '. ' +
          user?.middlename?.charAt(0).toUpperCase() +
          '. ',
        deleted: false,
        check: false,
      }))
      .filter(
        user => [TYPE_ROLE.WORKER, TYPE_ROLE.SUPERVISOR].includes(user.role_id) && !user?.is_fired,
      ),
);
